<template>
  <b-card>
    <validation-observer ref="accountRules" tag="form">
      <!-- form -->
      <b-form autocomplete="off" class="mt-2" @submit="validationForm" id="profile-form">
        <b-row>
          <b-col sm="12">
            <b-media no-body>
              <b-media-aside>
                <b-link>
                  <b-img
                    ref="previewEl"
                    rounded
                    :src="userData.avatar"
                    height="80"
                  />
                </b-link>
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <div
                  v-show="imageSrc"
                  class="my-2 w-64 h-64 object-fill mx-auto"
                  style="
                    object-fit: fill;
                    width: 16rem;
                    height: 16rem;
                    margin-left: auto;
                    margin-right: auto;
                  "
                >
                  <img
                    style="display: block; max-width: 100%"
                    class="block max-w-full"
                    ref="img"
                    :src="imageSrc"
                  />
                  <button
                    class="btn btn-gray text-danger close-crop-btn"
                    @click.stop.prevent="fileCleared"
                  >
                    <feather-icon icon="XIcon" size="22" />
                  </button>
                </div>

                <div class="btn-crop-container mt-2">
                  <b-button
                    v-if="!imageSrc"
                    class="btn btn-blue w-32 mx-2"
                    variant="primary"
                    @click.stop.prevent="imageInput.click()"
                  >
                    Select Image
                  </b-button>
                  <b-button
                    v-if="imageSrc && !cropped"
                    class="btn btn-gray w-32 mx-2"
                    variant="primary"
                    @click.stop.prevent="handleCropImage"
                  >
                    Crop
                  </b-button>

                  <input
                    type="file"
                    ref="imageInput"
                    accept=".jpg,jpeg,.png,.svg"
                    @change="fileChanged"
                    :style="{ display: 'none' }"
                  />

                  <b-button
                    class="btn btn-gray w-32 mx-2"
                    variant="outline-primary"
                    @click.stop.prevent="removeProfileAvatar"
                  >
                    Remove
                  </b-button>
                </div>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col md="12">
            <b-form-group label="Bio" label-for="bio">
              <b-form-textarea
                id="bio"
                v-model="generalData.bio"
                name="bio"
                placeholder="Bio"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="validationForm"
            >
              Update
            </b-button>
          </b-col>
          <b-col sm="6" class="mt-1">
            <b-form-group label="Username" label-for="account-username">
              <b-form-input
                v-model="userData.name"
                :disabled="true"
                placeholder="Name"
                name="name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <b-form-input
                v-model="userData.email"
                :disabled="true"
                name="email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6" v-if="userData.application_mode == 'company'">
            <b-form-group label="Employee Id" label-for="account-company">
              <b-form-input
                v-model="userData.employee_id"
                :disabled="true"
                name="Employee Id"
                placeholder="Employee Id"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="userData.application_mode == 'company'">
            <b-form-group
              label="Reporting Manager"
              label-for="reporting manager"
            >
              <b-form-input
                id="reporting-manager"
                v-model="userData.reporting_manager_name"
                type="text"
                :disabled="true"
                placeholder="Reporting Manager"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Date Of Joining" label-for="date of joining">
              <b-form-input
                id="date-of-join"
                v-model="userData.date_of_join"
                type="text"
                :disabled="true"
                placeholder="Date Of Joining"
              />
            </b-form-group>
          </b-col>
          <!-- buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  /* BFormFile, BButton, */ BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  /* BCardText, */ BMedia,
  BMediaAside,
  /* BMediaBody, */ BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import {
  ref,
  watchEffect,
  onMounted,
  onUnmounted,
  watch,
} from "@vue/composition-api";

import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import vueFilePond from "vue-filepond";
// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

import Cropper from "cropperjs";

export default {
  components: {
    // BButton,
    BForm,
    BImg,
    // BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    // BCardText,
    BMedia,
    BMediaAside,
    // BMediaBody,
    BLink,
    FilePond,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
      myFiles: [],
      userData: JSON.parse(JSON.stringify(this.generalData)),
    };
  },
  setup() {
    const imageInput = ref(null);
    const selectedFile = ref(null);
    const imageSrc = ref(null);
    const img = ref(null);
    const cropped = ref(false);
    const fileReader = new FileReader();
    let cropper = null;

    fileReader.onload = (event) => {
      imageSrc.value = event.target.result;
    };

    const handleCropImage = () => {
      cropper
        .getCroppedCanvas({
          width: 256,
          height: 256,
        })
        .toBlob((blob) => {
          selectedFile.value = blob;
          cropped.value = true;
        }, "image/jpeg");
    };

    const fileChanged = (e) => {
      const files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        selectedFile.value = files[0];
      }
    };

    const fileCleared = (_) => {
      selectedFile.value = null;
      cropped.value = false;
    };

    onMounted(() => {
      cropper = new Cropper(img.value, {
        aspectRatio: 1,
        minCropBoxWidth: 256,
        minCropBoxHeight: 256,
        viewMode: 3,
        dragMode: "move",
        background: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
      });
    });

    onUnmounted(() => {
      cropper.destroy();
    });

    watchEffect(() => {
      if (selectedFile.value) {
        fileReader.readAsDataURL(selectedFile.value);
      } else {
        imageSrc.value = null;
      }
    });

    watch(
      imageSrc,
      () => {
        if (imageSrc.value) {
          cropper.replace(imageSrc.value);
        }
      },
      {
        flush: "post",
      }
    );
    return {
      imageInput,
      selectedFile,
      fileChanged,
      fileCleared,
      imageSrc,
      img,
      handleCropImage,
      cropped,
    };
  },
  methods: {
    removeProfileAvatar() {
      let data = {};
      data.url = "/user/update-profile";
      data.params = {
        "remove-avatar": true,
      };

      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Profile Image removed",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Profile Image removing Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    validationForm() {
      const self = this;
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            const formData = new FormData(
              document.getElementById("profile-form")
            );

            formData.delete("avatar"); // data is the name of the original input file; import to delete

            if (this.selectedFile) {
              formData.append(`avatar`, this.selectedFile, "blobs.jpg");
            }

            self.$http
              .post("/user/update-profile", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                if (res.status == "200") {
                  self.$refs.accountRules.reset();
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Profile Update success.",
                      icon: "BellIcon",
                      text: res.data.message,
                      variant: "success",
                    },
                  });
                  this.refresh();
                } else {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Profile Update Failed!",
                      icon: "BellIcon",
                      text: res.data.message,
                      variant: "danger",
                    },
                  });
                }
              })
              .catch((error) => {
                // Do something on error
                if (error.response.status === 422) {
                  if (typeof error.response.data.errors !== "undefined") {
                    const keys = Object.keys(error.response.data.errors);
                    const values = Object.values(error.response.data.errors);
                    for (let i = 0; i <= keys.length; i += 1) {
                      if (
                        error.response.data.errors.hasOwnProperty.call(
                          values,
                          i
                        )
                      ) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: "Profile Update Failed",
                            icon: "BellIcon",
                            text: values[0][0],
                            variant: "danger",
                          },
                        });
                      }
                    }
                  }
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Profile Update Failed",
                      icon: "BellIcon",
                      text: "Oops! Something Went Wrong",
                      variant: "danger",
                    },
                  });
                }
              });
          } else {
            reject();
          }
        });
      });
    },
    refresh() {
      this.$http
        .get(`/user/${this.$cookies.get("userData").hashid}`)
        .then((res) => {
          this.userData = res.data.data;
          this.$cookies.set(
            "userData",
            res.data.data,
            60 * process.env.VUE_APP_LOGIN_TIMEOUT
          );
        });
    },
  },
};
</script>

<style lang="scss">
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "~cropperjs/dist/cropper.css";

.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}

.filepond--credits {
  display: none;
}
.close-crop-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.btn-crop-container {
  display: flex;
  justify-content: center;
  align-content: flex-end;
}
</style>