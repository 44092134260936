<template>
  <b-card>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <!-- general tab -->
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">General</span>
        </template>

        <account-setting-general v-if="Object.keys(options).length > 0" :general-data="options" />
      </b-tab>
      <!--/ general tab -->

      <!-- info -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="InfoIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Information</span>
        </template>

        <account-setting-information
          v-if="Object.keys(options).length > 0"
          :information-data="options"
        />
      </b-tab>

      <!-- change password tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Change Password</span>
        </template>

        <account-setting-password />
      </b-tab>
      <!--/ change password tab -->
      <!-- Manage Zoom -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="VideoIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Manage Zoom</span>
        </template>

        <account-setting-manage-zoom />
      </b-tab>
      <!--/ change password tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="Trash2Icon" size="18" class="mr-50" />
          <span class="font-weight-bold">Delete My Account</span>
        </template>

        <b-card>
          <!-- form -->
          <b-form>
            <b-row>
              <!-- buttons -->
                <b-col cols="12">
                  <button
                    type="button"
                    class="btn btn-danger"
                    style="height: 40px" 
                    @click="deleteMyAccount"
                  >
                    Delete My Account
                  </button>
                </b-col>
            </b-row>
            
          </b-form>
        </b-card>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTabs, BTab } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import AccountSettingInformation from "./AccountSettingInformation.vue";
import AccountSettingManageZoom from "./AccountSettingManageZoom.vue";
import { initialAbility } from "@/libs/acl/config";
import Vue from "vue";
import VueCookies from "vue-cookies";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
Vue.use(VueCookies);
export default {
  components: {
    BCard,
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingManageZoom,
  },
  data() {
    return {
      options: {},
      userData:this.$cookies.get("userData"),
    };
  },
  beforeCreate() {
    this.$http
      .get(`/user/${this.$cookies.get("userData").hashid}`)
      .then((res) => {
        this.options = res.data.data;
      });
  },
  methods:{
    deleteMyAccount(){
      this.$swal({
        title: "Do you want to permanently delete your account?",
        text: `You will delete your entire account and all records related to your account will be deleted permanently . This action cannot be reverted. are you sure?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteAccount();
        }
      });
    },
    deleteAccount(){
      this.$http
      .get(`/user/delete-account`)
      .then((res) => {
        if(res.data.success){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Your account is deleted",
              icon: "BellIcon",
              text: "You will be logged out and redirected to login page now.",
              variant: "success",
            },
          });
          this.logout();
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to delete your account",
              icon: "BellIcon",
              text: res.data.message,
              variant: "danger",
            },
          });
        }
      });
    },
    logout(){
      if (!useJwt.getToken()) {
        this.$router.push({ name: "auth-login" });
      }
      // Remove userData from localStorage
      useJwt.logout().then((res) => {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
        // Remove userData from localStorage
        localStorage.removeItem("userData");
        localStorage.removeItem("hasRedirect");
        localStorage.removeItem("EncryptedAbilities");
        // localStorage.clear()
        // Reset ability
        this.$ability.update(initialAbility);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Sign Out",
            icon: "BellIcon",
            text: res.data.message,
            variant: "success",
          },
        });
        // Redirect to login page
        this.$router.push({ name: "auth-login" });
      });
    }
  }
};
</script>
