<template>
  <b-card>
    <validation-observer ref="accountRules" tag="form">
      <!-- form -->
      <b-form autocomplete="off" @submit="validationForm" id="form">
        <b-row>
          <b-col md="6">
            <b-form-group label="Date of Birth " label-for="date-of-birth">
              <flat-pickr
                id="date-of-birth"
                v-model="informationData.date_of_birth"
                :config="{ dateFormat: 'Y-m-d', maxDate: 'today' }"
                class="form-control"
                name="date_of_birth"
                placeholder="Select Date.."
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <ValidationProvider
              #default="{ errors }"
              name="Mobile"
              rules="numeric|min:6"
            >
              <b-form-group label="Mobile" label-for="mobile">
                <b-form-input
                  id="mobile"
                  v-model="informationData.mobile"
                  placeholder="Mobile Number"
                  name="mobile"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Emergency Contact Number"
              rules="numeric|min:6"
            >
              <b-form-group
                label="Emergency Contact Number"
                label-for="emergency-contact-number"
              >
                <b-form-input
                  id="emergency-contact-number"
                  v-model="informationData.emergency_contact_number"
                  name="emergency_contact_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Emergency Contact Number"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="Father Name"
              rules="alpha_spaces"
            >
              <b-form-group label="Father Name" label-for="father-name">
                <b-form-input
                  id="father-name"
                  v-model="informationData.father_name"
                  name="father_name"
                  placeholder="Father Name"
                />
                <!-- :state="errors.length > 0 ? false : null" -->
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="Emergency Contact Name"
              rules="alpha_spaces"
            >
              <b-form-group
                label="Emergency Contact Name"
                label-for="emergency-contact-name"
              >
                <b-form-input
                  id="emergency-contact-name"
                  v-model="informationData.emergency_contact_name"
                  name="emergency_contact_name"
                  type="text"
                  placeholder="Emergency Contact Name"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="Spouse Name"
              rules="alpha_spaces"
            >
              <b-form-group label="Spouse Name" label-for="spouse-name">
                <b-form-input
                  id="spouse-name"
                  v-model="informationData.spouse_name"
                  name="spouse_name"
                  type="text"
                  placeholder="Spouse Name"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <h5 class="col-12">Current Address</h5>
          <b-col md="6">
            <b-form-group label="Address Line 1" label-for="address-line-1">
              <b-form-input
                id="address-line-1"
                v-model="informationData.location.add_line_1"
                name="location.add_line_1"
                type="text"
                placeholder="Address Line 1"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Address Line 2" label-for="address-line-2">
              <b-form-input
                id="address-line-2"
                v-model="informationData.location.add_line_2"
                name="location.add_line_2"
                type="text"
                placeholder="Address Line 2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Postcode" label-for="postcode">
              <b-form-input
                id="postcode"
                v-model="informationData.location.post_code"
                name="post_code"
                type="text"
                placeholder="Postcode"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="City" label-for="city">
              <b-form-input
                id="city"
                v-model="informationData.location.city"
                name="city"
                type="text"
                placeholder="City"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="State" label-for="state">
              <b-form-input
                id="state"
                v-model="informationData.location.state"
                name="state"
                type="text"
                placeholder="State"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Country" label-for="country">
              <b-form-input
                id="country"
                v-model="informationData.location.country"
                name="country"
                type="text"
                placeholder="Country"
              />
            </b-form-group>
          </b-col>
          <h5 class="col-12 d-flex justify-content-between">
            <div>Permanent Address</div>
            <div>
              Same as Current Address
              <b-button
                type="button"
                variant="primary"
                size="sm"
                @click="copyLocation"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </div>
          </h5>
          <b-col md="6">
            <b-form-group label="Address Line 1" label-for="address-line-1">
              <b-form-input
                id="address-line-1"
                name="permanent_address.add_line_1"
                v-model="permanent_address.add_line_1"
                :value="informationData.permanent_address.add_line_1"
                type="text"
                placeholder="Address Line 1"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Address Line 2" label-for="address-line-2">
              <b-form-input
                id="address-line-2"
                v-model="permanent_address.add_line_2"
                :value="informationData.permanent_address.add_line_2"
                name="permanent_address.add_line_2"
                type="text"
                placeholder="Address Line 2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Postcode" label-for="postcode">
              <b-form-input
                id="postcode"
                v-model="permanent_address.post_code"
                :value="informationData.permanent_address.post_code"
                name="post_code"
                type="text"
                placeholder="Postcode"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="City" label-for="city">
              <b-form-input
                id="city"
                v-model="permanent_address.city"
                :value="informationData.permanent_address.city"
                name="city"
                type="text"
                placeholder="City"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="State" label-for="state">
              <b-form-input
                id="state"
                v-model="permanent_address.state"
                :value="informationData.permanent_address.state"
                name="state"
                type="text"
                placeholder="State"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Country" label-for="country">
              <b-form-input
                id="country"
                v-model="permanent_address.country"
                :value="informationData.permanent_address.country"
                name="country"
                type="text"
                placeholder="Country"
              />
            </b-form-group>
          </b-col>

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="validationForm"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      data_local: {},
      permanent_address: {},
    };
  },
  created() {
    this.permanent_address = this.informationData.permanent_address;
  },
  methods: {
    copyLocation() {
      this.permanent_address.add_line_1 =
        this.informationData.location.add_line_1;
      this.permanent_address.add_line_2 =
        this.informationData.location.add_line_2;
      this.permanent_address.city = this.informationData.location.city;
      this.permanent_address.state = this.informationData.location.state;
      this.permanent_address.country = this.informationData.location.country;
      this.permanent_address.post_code =
        this.informationData.location.post_code;
    },
    validationForm() {
      const self = this;
      // return;
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            // const formData = new FormData(document.getElementById("form"));
            self.informationData.permanent_address = self.permanent_address;
            self.$http
              .post("/update-profile-requests", self.informationData)
              .then((res) => {
                if (res.status == "201") {
                  self.$refs.accountRules.reset();
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Update Request Sent.",
                      icon: "BellIcon",
                      text: "Your request has been sent for review",
                      variant: "success",
                    },
                  });
                } else {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Update Request Submission Failed!",
                      icon: "BellIcon",
                      text: res.data.message,
                      variant: "danger",
                    },
                  });
                }
              })
              .catch((error) => {
                // Do something on error
                if (error.response.status === 422) {
                  if (typeof error.response.data.errors !== "undefined") {
                    const keys = Object.keys(error.response.data.errors);
                    const values = Object.values(error.response.data.errors);
                    for (let i = 0; i <= keys.length; i += 1) {
                      if (
                        error.response.data.errors.hasOwnProperty.call(
                          values,
                          i
                        )
                      ) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: "Update Request Failed",
                            icon: "BellIcon",
                            text: values[0][0],
                            variant: "danger",
                          },
                        });
                      }
                    }
                  }
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Update Request Failed",
                      icon: "BellIcon",
                      text: "Oops! Something Went Wrong",
                      variant: "danger",
                    },
                  });
                }
              });
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
